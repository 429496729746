
// $font-family-sans-serif: "Roboto", sans-serif;
$font-family-sans-serif: "Arimo";

$font-weight-base: 400;
$headings-font-weight: $font-weight-base;

// need to copy $font-size-base because of import order
$font-size-base: 1rem;
$font-size-lg: $font-size-base * 1.5;
$font-size-sm: $font-size-base * 0.75;

$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-sm;

// colors
$plone-link-color: #4BACD0;
$list-group-border-color: #000;  // see BS -> $dark
$border-color: #000;
$dark: #000;

// card
$card-border-radius: 0;
$card-border-color: #212529;

// borders
$border-radius: 0;

// grid
$spacer: 1rem;
$grid-gutter-width: 2.1875rem;
$gutters: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 2.1875,
  5: $spacer * 4.375,
);

// offcanvas
$offcanvas-horizontal-width: 425px;

// modal
$modal-dialog-margin: 0;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
