@include media-breakpoint-up(lg) {
    .minh-100 {
        min-height: 100%;
    }

    .logo-column {
        border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }
}

a {
    position: relative;
    text-decoration: none;

    &:hover {
        color: $plone-link-color;
        text-decoration: underline 1px;
        text-underline-position: under;
    }

    &.link-dark {
        color: black;
    }

    &.link-light {
        color: white;
    }
}

hr {
    opacity: 1;
}

#portal-logo {
    display: block;
    width: 150px;

    @include media-breakpoint-up(lg) {
        margin-top: 85px;
    }

    &::before {
        height: 0;
    }
}

article>header {
    margin-bottom: 0;
}

#toplinks,
#filter,
#search-info {
    height: auto;

    @include media-breakpoint-up(lg) {
        height: 160px;
    }
}

#filter input[name="SearchableText"] {
    padding: ($spacer * .5) 0;
}

.pat-structure #filter {
    height: auto;
}

@include media-breakpoint-down(lg) {
    #toplinks {
        margin-bottom: $spacer;
    }

    article>header {
        margin-bottom: $spacer * 3.5;
    }
}


#menu-offcanvas {
    position: absolute;
    top: 1.5rem;
    right: 0;

    @include media-breakpoint-up(lg) {
        position: relative;
        top: 0;
    }

    @include media-breakpoint-down(lg) {
        width: auto;

        [data-bs-toggle] svg {
            fill: white;
            filter: drop-shadow(0 0 0.03rem rgb(0, 0, 0));
        }
    }

    ul.subnav li {
        padding-top: 0.4rem;
        padding-bottom: 0.7rem;
    }

    .offcanvas-body {
        margin-top: 2rem;

        @include media-breakpoint-up(lg) {
            margin-top: 10.2rem;
        }
    }

}

main {
    @include media-breakpoint-up(lg) {
        margin-bottom: 9rem;
    }
}

#mainslider {
    width: 100%;
    height: 75vh;
    position: relative;

    @include media-breakpoint-up(lg) {
        height: 100vh;
    }

    .mainslider-caption {
        position: absolute;
        left: -25px;
        bottom: 25px;
        transform: rotate(-90deg);
        transform-origin: left bottom;
        @extend .fs-6;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

#mainnav .list-group {
    --bs-list-group-bg: $dark;
    --bs-list-group-color: white;
}

#mainnavLabel {
    margin-top: -2rem;

    @include media-breakpoint-up(lg) {
        margin-top: 2.6rem;
    }
}

.callout,
.Factbox {
    @extend .border-start;
    @extend .border-5;
    @extend .border-dark;
    @extend .bg-light;
    @extend .p-3;
}

.event-title {
    font-size: $h5-font-size;
    max-width: 80%;

    &.trimmed {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
    }
}

.event-exclamation {
    max-width: 50%;
}

.text-title {
    @include media-breakpoint-up(lg) {
        font-size: $h3-font-size;
    }
}

.event-preview-container {
    text-align: left;
}

// pat-inject status
.executing::after {
    content: " ";
    @extend .spinner-grow;
    --bs-spinner-width: 1rem;
    --bs-spinner-height: 1rem;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
    position: absolute;
    left: 50%;
}

#texte,
#termine {
    position: relative;

    // &.injecting::before {
    //     content: " ";
    //     position: absolute;
    //     top: 1rem;
    //     left: 45%;
    //     z-index: 1000;
    //     @extend .spinner-grow;
    // }

}

#termine>li {
    min-height: 3.5rem;

    time {
        text-transform: capitalize;
    }
}

#texte {
    .item-content {
        min-height: 10.5rem;
    }

    @include media-breakpoint-down(lg) {
        >.col-6:nth-child(odd) {
            border-right: solid 1px black;
            padding-right: 0;

            .text-title {
                margin-right: $spacer;
            }
        }

        >.col-6:nth-child(even) {
            padding-left: 0;

            .text-title {
                margin-left: $spacer;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .line-clamp {
            margin-top: 1.4rem;
            margin-bottom: 1.8rem;
        }
    }
}

#section-text h2,
h2.Zwischentitel {
    font-size: $font-size-base;
    margin-bottom: $spacer;
    font-weight: $font-weight-bold;
}


#texte-nextpage,
#termine-nextpage {
    text-transform: uppercase;
}

li.texte-mobile {
    .text-title {
        min-height: 7.5rem;
    }

    >.col-6:first-child {
        border-right: solid 1px black;
        padding-right: 0;

        .text-title {
            margin-right: $spacer;
        }
    }

    >.col-6:not(:first-child) {
        padding-left: 0;

        .text-title {
            margin-left: $spacer;
        }
    }
}

.filter-label {
    text-transform: uppercase;
    display: block;
    line-height: $line-height-sm;

    @include media-breakpoint-down(lg) {
        margin-bottom: $spacer * .5;

        &.show {
            --bs-border-color: transparent;
        }
    }

    .filter-label-text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 90%;
    }
}

.form-control::placeholder {
    color: black;
}

.search-input {
    padding-left: 0 !important;
}

.text-muted {
    color: $gray-500;
}

@include media-breakpoint-down(lg) {
    .search-form {
        border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }
}

.border-bottom-n1 {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    margin-bottom: -1px;
}

// carousel images
.carousel {
    margin-bottom: 4rem;
}

.carousel-item {
    img {
        height: 270px;
        width: auto;
        object-fit: contain;
    }

    @include media-breakpoint-up(lg) {
        .credit {
            position: absolute;
            transform: rotate(-90deg);
            width: 440px;
            top: 200px;
            right: -140px;
            text-align: left;
        }

        img {
            height: 540px;
        }

    }

}

.carousel-control-next,
.carousel-control-prev {
    width: 5%;
    top: 340px;
    bottom: auto;

    @include media-breakpoint-up(lg) {
        top: 600px;
    }

}

.line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--line-clamp);
    overflow: hidden;
}

// fullcalendar fix
#filter-calendar {
    a {
        @extend .link-dark;
    }

    .fc {
        --fc-border-color: white;
        --fc-today-bg-color: rgb(240, 240, 240);
        --fc-button-text-color: black;
        --fc-button-bg-color: transparent;
        --fc-button-border-color: transparent;
        --fc-button-hover-bg-color: transparent;
        --fc-button-hover-border-color: transparent;
        --fc-button-active-bg-color: transparent;
        --fc-button-active-border-color: transparent;
        --fc-neutral-bg-color: transparent;

        .fc-toolbar {

            &,
            .fc-toolbar-title {
                @extend .fs-5;
            }

            .fc-toolbar-title {
                padding-left: 1.1rem;
            }

            &.fc-header-toolbar {
                margin-bottom: 0;
            }

            .fc-button {
                padding: 0.3rem 0.8rem;
            }
        }

        .fc-view-harness {
            .fc-daygrid-day-events {
                display: none;
            }

            .fc-col-header,
            .fc-daygrid-body,
            .fc-scrollgrid-sync-table {
                width: 100% !important;
            }

            .fc-daygrid-day-top {
                justify-content: center;
            }

            .fc-col-header-cell-cushion {
                font-size: 0;

                &:first-letter {
                    @extend .fs-5;
                }
            }
        }

        a.fc-daygrid-day-number {
            cursor: pointer;
        }

    }
}

// dark modal
.modal-content.bg-dark .modal-close {
    filter: invert(1);
}


// banners
.banner {
    height: 10.5rem;
    overflow: hidden;

    &.banner-size-1x2 {
        height: 21rem;
    }

    &.banner-size-1x3 {
        height: 31.5rem;
    }

    .banner-link {
        display: block;
        position: absolute;
        top: 5px;
        bottom: 5px;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .banner-text {
        text-decoration: none;
        color: black;
        position: absolute;
        left: 5px;
        bottom: 0;
        @extend .fs-3;
    }

    @include media-breakpoint-down(lg) {

        &,
        &.banner-size-1x2,
        &.banner-size-1x3 {
            height: 9.55rem;
            border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);

            >div {
                border-top: none;
            }
        }
    }
}

// easyform
.easyformForm {

    label .required::after,
    label.required::after,
    .invalid-feedback {
        color: $primary;
    }

    .form-control {
        border-style: none none solid none;
        border-color: black;
    }

}

@include media-breakpoint-up(lg) {
    #link-ausstellungskalender a {
        font-size: $spacer * 1.5;
    }
}

#nlOffcanvas {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 1px;
}

.event-state-postponed,
.event-state-cancelled {
    text-decoration: line-through;
}

.label-state-postponed,
.label-state-cancelled {
    color: $danger;
}


// print style
@media print {
    body.plone-toolbar-left.plone-toolbar-left-expanded {
        padding-left: 0 !important;
    }

    .col-print-12 {
        width: 100% !important;
        margin-bottom: $spacer;
    }

    h1 {
        margin-bottom: $spacer !important;
    }
}

// tiny fix
.mce-content-body {
    padding: .25rem;
}
