/* GENERATED WITH google-webfonts-helper.herokuapp.com/fonts/arimo */

/* arimo-regular - latin */
@font-face {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* arimo-500 - latin */
  @font-face {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 500;
    src: local(''),
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* arimo-600 - latin */
  @font-face {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 600;
    src: local(''),
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* arimo-italic - latin */
  @font-face {
    font-family: 'Arimo';
    font-style: italic;
    font-weight: 400;
    src: local(''),
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* arimo-700 - latin */
  @font-face {
    font-family: 'Arimo';
    font-style: normal;
    font-weight: 700;
    src: local(''),
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* arimo-500italic - latin */
  @font-face {
    font-family: 'Arimo';
    font-style: italic;
    font-weight: 500;
    src: local(''),
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* arimo-700italic - latin */
  @font-face {
    font-family: 'Arimo';
    font-style: italic;
    font-weight: 700;
    src: local(''),
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* arimo-600italic - latin */
  @font-face {
    font-family: 'Arimo';
    font-style: italic;
    font-weight: 600;
    src: local(''),
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('++theme++kulturzeitschrift-theme/fonts/arimo-v27-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
